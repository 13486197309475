@import '../../../../../public/assets/scss/base';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.button-custom-padding{
    padding : 0 27.8px !important;
}


.uploadedImage {
    @include pos(r, null);

    &>span {
      @include square(72px);
      @include radius(8px);
      background-color: $theme-grey-400;
      overflow: hidden;
      display: block;

      &>img {
        @include square(100%);
        object-fit: cover;
      }
    }

    &>a {
      @include pos(ab, -10px -10px null null);
      @include circle(24px);
      border: 2px solid $white;
      background-color: $theme-grey-900;
      color: $white;
      font-size: 10px;
      text-align: center;
      line-height: 20px;

      &:hover {
        background-color: $theme-danger;
      }
    }
  }
  .no-option-inventory{
    margin-right: 20px !important;
  }
  .yes-option-inventory{
    margin-left: 5px !important;
  }
  .inventory-check-styles{
      margin-left: 0.5rem !important;
      margin-top: 5px;
  }