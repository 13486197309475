// THEME AND COLOR
$black: #000000 !default;
$white: #ffffff !default;
$theme: #00D19F !default;
$theme-sky-blue: #6DC7E8 !default;
$theme-grey: #999999 !default;
$theme-primary-S3: #96F8E0 !default;
$theme-secondary: #153230 !default;
$theme-secondary-S1: #2D4543 !default;
$theme-secondary-S2: #4C6B69 !default;
$theme-secondary-S3: #759391 !default;
$theme-secondary-S4: #A7D0CD !default;
$theme-secondary-S5: #D7FFF5 !default;

// COLORS GRAY SHADES
$theme-grey-900: #141616 !default;
$theme-grey-800: #3C4143 !default;
$theme-grey-700: #646C70 !default;
$theme-grey-600: #8E979B !default;
$theme-grey-500: #BCC0C3 !default;
$theme-grey-400: #D8D8D8 !default;
$theme-grey-300: #E6E6E6 !default;
$theme-grey-200: #F4F4F4 !default;

$theme-platinum: #E2E2E2 !default;
$theme-light-grey: #CECECE !default;
$theme-slate-grey: #759391 !default;

// COLOR STATUS
$theme-active: #0F7EFF !default;
$theme-danger: #E73F3F !default;
$theme-warning: #EFCC4F !default;
$theme-warning-dark: #FF8F28 !default;
$theme-success: #9FD281 !default;
$theme-pending: #EDA34C !default;
$theme-green: #18C233 !default;
$theme-green-dark: #52A86E !default;

// COLOR TINTS
$theme-light-green: #E2FFE6 !default;
$theme-tint-danger: #FFF1F1 !default;
$theme-tint-warning: #FFF4E4 !default;
$theme-tint-active: #E4F0FF !default;
$theme-tint-green: #DAFFE2 !default;
$theme-tint-success: #E8F6ED !default;
// $theme-tint-grey: #F6F6F6 !default;

// $theme-tint-fawn: #FCF7F3 !default;
// $theme-tint-space-blue: #F0F2F5 !default;
// $theme-tint-sky-blue: #EEFBFF !default;
// $theme-tint-purple: #F4F0F7 !default;
// $theme-tint-yellow: #FFF9E5 !default;
// $theme-platinum: #E8E8E8 !default;

// PATH & FONT NAME
$font: "Plus Jakarta Sans" !default;
$font-alternative: 'Inter', sans-serif !default;
$font-awesome: "Font Awesome 5 Free" !default;
$imgpath : ".~../public/assets/images/" !default;
$font-path : "../../../assets/fonts/" !default;
$icon-font-path : "../../assets/fonts/icons" !default;
$url : $imgpath !default;

// FONTS and TEXT STYLE
$fontnormal : 14px !default;
$fontsmall : 12px !default;
$lineheight : 1.5 !default;

// Display
$in : inline !default;
$ib : inline-block !default;
$b : block !default;
$t : table !default;
$cell : table-cell !default;
$n : none !default;

// Margin
$a : auto !default;
$auto : auto auto !default;
$za : 0 auto !default;

// Ratio
$imageRatioVar: 16 8 !default;
$imageRatio_16_9: 16 9 !default;