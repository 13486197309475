.payout-notification-modal{
    // padding-top:20px !important;
    padding:20px 30px 30px 30px !important;
}
.payout-notification-modal h4{
    font-size: 20px !important;
}
.payout-btns{
    margin-top: 20px !important;
    width: 84px !important;
    min-width: 84px !important;
}