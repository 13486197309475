.payoutAccountInfoModal {
    .modal-body {
      padding: 2rem;
      border-radius: 10px;
      background-color: #fff;
    }
  
    .closeButton {
      position: absolute;
      top: 15px;
      right: 15px;
      background: none;
      border: none;
      font-size: 1.2rem;
    }
  
    .formWrap {
      max-width: 500px;
      margin: 0 auto;
    }
  
    
  
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  
    h5 {
      font-size: 1.2rem;
      font-weight: 500;
    }
  
    h6 {
      font-size: 1rem;
      font-weight: 400;
    }
  
  }