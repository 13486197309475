@import "../../../public/assets/scss/base";

.usersWrapper {
  @include padding(20 0);

  .button-filter {
    background-color: $white;
    border-color: $theme-grey-300;
    color: $theme-secondary;
    font-weight: 600;
    font-family: $font-alternative;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.05),
      0px 1px 2px rgba(24, 24, 28, 0.05);
  }
}

.onBoardingListWrapper {
  &.card{
    border-color: $theme-grey-300;
  }
  .step {
    @include display-flex;
    @include padding-bottom(20);
    @include pos(r, null);
    gap: 15px;
    &:not(:last-child) {
      ._count {
        &:before {
          content: "";
          border-left: 1px solid $theme-grey-300;
          @include pos(ab, 0 null 0 17px);
        }
      }
    }
    ._count {
      @include flex(0 0 35px);
      & > span {
        @include circle(35px);
        @include pos(r, null);
        z-index: 1;
        border: 1px solid $theme-grey-300;
        text-align: center;
        line-height: 33px;
        font-size: 14px;
        font-weight: 600;
        background-color: $white;
      }
    }
    .stepsCard {
      background-color: $white;
      border: 1px solid $theme-grey-300;
      border-radius: 6px;
      padding: 15px;

      ._titleLabel {
        font-size: 12px;
        font-weight: 500;
        color: $theme-grey-600;
        margin-bottom: 7px;
        display: block;
      }

      h4 {
        font-size: 14px;
        margin: 0;
        color: $theme-grey-800;
      }
      .status {
        @include padding(5 10);
        @include radius(30px);
        display: inline-block;
        font-size: 12px;
        font-weight: 600;

        &--active {
          background-color: $theme-tint-active;
          color: $theme-active;
        }

        &--danger {
          background-color: $theme-tint-danger;
          color: $theme-danger;
        }

        &--success {
          background-color: $theme-tint-success;
          color: $theme-green-dark;
        }

        &--warning {
          background-color: $theme-tint-warning;
          color: $theme-warning-dark;
        }

        .dot {
          @include circle(7);
          background-color: $theme-active;
        }
      }
    }
  }
}
