
.pagination {
    &-container {
        padding: 0 !important;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-text-container {
        flex: 2;
        display: flex;
        font-size: small;
        font-weight: 400;

        // margin-left: 20px;
        padding-bottom: 0;

        align-items: center;

        b {
            padding: 0 3.5px;
        };
    }

    &-button-container {
        display: flex;
        flex: 1;
        
        justify-content: flex-end;
        gap: 15px;
    }
}