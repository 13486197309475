// User Interface ------------------------------------------------------------
// This file can be expanded to handle all the user interface properties as
// they become available in browsers:
// http://www.w3.org/TR/2000/WD-css3-userint-20000216
@import "shared";


// This property controls the selection model and granularity of an element.
//
// @param $select
//   [ none | text | toggle | element | elements | all | inherit ]
@mixin user-select($select) {
  $select: unquote($select);
  @include experimental(user-select, $select,
    -moz, -webkit, not(-o), -ms, -khtml, official
  );
}

// Style the html5 input placeholder in browsers that support it.
//
// The styles for the input placeholder are passed as mixin content
// and the selector comes from the mixin's context.
//
// For example:
//
//     #{elements-of-type(text-input)} {
//       @include input-placeholder {
//         color: #bfbfbf;
//         font-style: italic;
//       }
//     }
//
// if you want to apply the placeholder styles to all elements supporting
// the `input-placeholder` pseudo class (beware of performance impacts):
//
//     * {
//       @include input-placeholder {
//         color: #bfbfbf;
//         font-style: italic;
//       }
//     }
@mixin input-placeholder {
  &:-ms-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}
