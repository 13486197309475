@import '../../../../../public/assets/scss/base';

.roomCard{
  // border: none;
  border: solid $theme-grey-500 1px;

  @include radius(8px);
  
  & > .card-body{
    @include padding(10);
    h5{
      font-size: 14px;
      color: $theme-grey-800;
    }
    ._room{
      color: $theme-green;   
    }
  }
}