// Ensure CSS grid works with IE 11 spec.
// https://css-tricks.com/browser-compatibility-css-grid-layouts-simple-sass-mixins/
// sass-lint:disable no-vendor-prefixes, no-duplicate-properties
@mixin display-grid {
  display: -ms-grid;
  display: grid;
}

// $columns values should be delimited by a space
@mixin grid-template-columns($columns...) {
  -ms-grid-columns: $columns;
  grid-template-columns: $columns;
}

// $rows values should be delimited by a space
@mixin grid-template-rows($rows...) {
  -ms-grid-rows: $rows;
  grid-template-rows: $rows;
}

// Can be used in combination with above grid-template-X mixins.
// These result in the same output:
// @include grid-template-columns(10px grid-repeat(4, 20px) 30px);
// @include grid-template-columns(10px 20px 20px 20px 20px 30px);
@function grid-repeat($repeat, $stuff: 1fr) {
  $list: ();
  @for $i from 1 through $repeat {
    $list: append($list, $stuff, space);
  }
  @return $list;
}

@mixin grid-column($col-start, $col-end) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-end - $col-start;
  grid-column: #{$col-start} / #{$col-end};
}

@mixin grid-row($row-start, $row-end) {
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-end - $row-start;
  grid-row: #{$row-start} / #{$row-end};
}

@mixin grid-align-self($value) {
  -ms-grid-row-align: $value;
  align-self: $value;
}

@mixin grid-justify-self($value) {
  -ms-grid-column-align: $value;
  justify-self: $value;
}
