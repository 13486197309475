@import '../../../../../public/assets/scss/base/';
.filter-bar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bookingsWrapper, .courseDetail {
  @include padding(20 0);
  .button-filter {
    background-color: $white;
    border-color: $theme-grey-300;
    color: $theme-secondary;
    font-weight: 600;
    font-family: $font-alternative;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.05), 0px 1px 2px rgba(24, 24, 28, 0.05);
  }
  .filter-item-button {
    // border-color: $theme-grey-300;
    color: $theme-secondary;
    background-color: transparent;
    border: 1px transparent solid;
    box-shadow: none;
    font-weight: 600;
    font-family: $font-alternative;
    height: 40px;
    border-radius: 7px;
  }
  .filter-subitem-selected {
    color: $theme;
  }
  .filter-subitem-container {
    background-color: $white;
    display: flex;
    flex-direction: row;
    
    border-radius: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.05), 0px 1px 2px rgba(24, 24, 28, 0.05);
    border: 1px $theme-grey-300 solid;
    border-left: 0;
    padding: 0;
    margin: 0;
    // align-items: center;
    .filter-item-button {
      font-weight: 500;
      border-radius: 0;
    }
  }
  
  .filter-item-selected {
    background-color: $white;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.05), 0px 1px 2px rgba(24, 24, 28, 0.05);
    border: 1px $theme-grey-300 solid;
    
    &-folder {
      // border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      position: relative;
      // box-shadow: 1px 0px 10px $theme-grey-300;
      // z-index: 50000;
    }
    // color: $theme;
  }
  .filter-item-selected, .filter-subitem-container {
    height: 45px;
  }
  
  .text-align-right{
    text-align: right;
  }
}