@import '../../../public/assets/scss/base';

.sideMenuWrapper {
  @include pos(f, 0 null 0 0);
  width: 230px;
  background-color: $theme-secondary;
  overflow: auto;

  &__ {
    &logo {
      @include padding(15 20);
    }

    &title {
      color: $theme-secondary-S3;
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.7px;
    }

    &navigation {
      @include padding(15 20);

      ul {
        @include padding-left(5);
        @include margin(0);

        li {
          @include padding(10 0);

          .nav-item {
            color: $theme-secondary-S3;
            font-weight: 600;
            @include display-flex;
            @include align-items(center);

            &:hover,
            &.active {
              color: $theme;
            }

            &>em {
              @include flex(0 0 22px);
              @include square(22px);
              font-size: 18px;
              line-height: 23px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.bookings-subitems {
  transition: max-height 600ms ease-in-out;
  overflow: hidden;
  max-height: 400px;
  height: 100%;
  
  &-hide {
    max-height: 0;
  }
}