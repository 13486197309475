// input,
// select {
//   &.form-control, &.form-select {
//     @include radius(12px);

//     &.form-control-lg {
//       height: 48px;
//       @include fontsize(16);
//     }
//   }
// }

.form-control,
.form-select {
  font-weight: 400;
  color: $theme-secondary;
  border-color: $theme-grey-400;
  @include radius(8px);

  &.control-lg {
    height: 50px;
  }

  &.control-xl {
    height: 56px;
  }

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      font-weight: 300;
    }
  }

  @include input-placeholder {
    color: $theme-light-grey;
  }

  &.isInvalid {
    border-color: $theme-danger !important;
  }

  &:focus {
    border-color: $theme;
    @include box-shadow-none;
  }

  // &:read-only {
  //   background-color: $theme-grey-400;
  //   border-color: $theme-grey-400;
  // }
}

.search-container {
  border: 1px solid;
  border-color: $theme-grey-400;
  transition: 200ms border ease-in-out;
  display: inline-flex;
  width: 40%;
  max-width: 300px;
  height: 40px;
  flex-direction: row;

  background-color: white;

  @include radius(8px);

  &.control-lg {
    height: 50px;
  }

  &.control-xl {
    height: 56px;
  }
}

.search-icon-container {
  padding-left: 10px;
  color: $theme-grey-800;
  font-weight: bold;
}

.search-container:has(input:focus) {
  border-color: $theme;
  @include box-shadow-none;
}

.search-control {
  @extend .form-control;

  font-weight: 400;
  color: $theme-secondary;
  
  padding: 5.25px 8px;

  width: 100%;

  border: none;

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      font-weight: 300;
    }
  }

  @include input-placeholder {
    color: $theme-grey-500;
  }

  &.isInvalid {
    border-color: $theme-danger !important;
  }

  
  // &:read-only {
  //   background-color: $theme-grey-400;
  //   border-color: $theme-grey-400;
  // }
}

.control-label {
  sup {
    color: $theme-danger;
  }
}

.isInvalidMessage {
  @include fontsize(13);
  font-weight: 500;

  &.text-danger {
    color: $theme-danger !important;
  }

  &>p {
    @include margin(0);
    @include fontsize(13);
    font-weight: 500;
  }
}


.form-floating {

  &>.form-control,
  &>.form-control-plaintext,
  &>.form-select {
    height: calc(4rem + 2px);
    font-weight: 600;
    color: $theme-secondary;
  }

  &>label {
    padding: 1.3rem 0.75rem;
    letter-spacing: 0.3px;
    font-weight: 500;
    color: $theme-grey-600;
  }

  &.csReactDatePicker {

    .react-datepicker-popper {
      .react-datepicker {
        font-family: $font, 'sans-serif';
        box-shadow: 0px 28px 28px rgba(0, 0, 0, 0.3);
        border-color: $theme-grey-200;

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__navigation {
          top: 5px;

          .react-datepicker__navigation-icon {
            top: 5px;

            &:before {
              border-color: $theme-secondary;
            }
          }
        }

        .react-datepicker__header {
          background-color: $white;
          border: none;
          padding: 15px 0;

          .react-datepicker__current-month {
            font-size: 12px;
            font-weight: 700;
            border-color: $theme-secondary;
            margin-bottom: 10px;
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              font-size: 12px;
              font-weight: 700;
              border-color: $theme-secondary;
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              color: $theme-grey-600;
              font-weight: 600;
              font-size: 12px;

              &.react-datepicker__day--outside-month {
                color: darken($white, 10%);
              }

              &.react-datepicker__day--today {
                font-weight: 800;
              }

              &.react-datepicker__day--selected,
              &.react-datepicker__day--keyboard-selected {
                background-color: $theme;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.reactCSSelect {
  .reactCSSelect__control {
    @include radius(30px);
    background-color: rgba($theme-grey-400, 0.2);
    border-color: rgba($theme-grey-400, 0.2);
    height: 48px;
    font-size: 12px;
    color: lighten($black, 10%);
    font-weight: 700;
    box-shadow: none;

    &--is-focused,
    &--menu-is-open {
      border-color: $theme;
    }

    .reactCSSelect__value-container {
      // @include padding(15 0 0);
      // @include margin(0);

      .reactCSSelect__placeholder,
      .reactCSSelect__input-container {
        @include margin(0);
      }
    }

    .reactCSSelect__indicator-separator {
      display: none;
    }
  }

  .reactCSSelect__menu {
    .reactCSSelect__menu-list {
      .reactCSSelect__option {
        font-size: 12px;
        font-weight: 600;

        &--is-selected {
          background-color: $theme;
        }
      }
    }
  }
}

.rangeSliderWrap {
  &.multi-range-slider {
    @include padding(20 0);
    @include box-shadow-none;
    border: 0;

    .bar {

      .bar-left,
      .bar-right,
      .bar-inner {
        @include box-shadow-none;
        border: none;
      }

      .thumb {
        background-color: $white;
        border: none;

        &:before {
          border: none;
          @include box-shadow(0 0 0 3px $theme inset);
        }

        .caption {

          &>* {
            background-color: $white;
            border: 1px solid darken($white, 10%);
            color: $theme-secondary;
            font-weight: 700;
            @include box-shadow-none;
            @include radius(5px);
          }
        }
      }
    }

    .labels {
      .label {
        width: auto;
        color: $theme-secondary-S2;
        font-weight: 600;
      }
    }
  }
}

.csReactSelectFloating {
  &.csReactSelectFloating-lg {
    &>label {
      @include transform(scale(1) translateY(12px) translateX(12px));
    }

    .csReactSelect {
      .csReactSelect__control {
        height: 56px;

        .csReactSelect__value-container {
          @include padding(20 0 0);
        }
      }
    }
  }

  &>label {
    @include opacity(1);
    @include transform(scale(1) translateY(7px) translateX(12px));
    @include padding(0);
    height: auto;
    width: auto;
    font-size: 10px;
    font-weight: 600;
    color: $theme-grey-700;
  }

  .csReactSelect {
    .csReactSelect__control {
      @include radius(12px);
      @include padding-left(11);
      background-color: $white;
      border-color: $theme-secondary-S2;
      height: 48px;
      font-size: 12px;
      color: lighten($black, 10%);
      font-weight: 700;
      box-shadow: none;

      &--is-focused,
      &--menu-is-open {
        border-color: $theme;
      }

      .csReactSelect__value-container {
        @include padding(15 0 0);
        @include margin(0);

        .csReactSelect__placeholder,
        .csReactSelect__input-container {
          @include margin(0);
        }
      }

      .csReactSelect__indicator-separator {
        display: none;
      }
    }

    .csReactSelect__menu {
      .csReactSelect__menu-list {
        .csReactSelect__option {
          font-size: 12px;
          font-weight: 600;

          &--is-selected {
            background-color: $theme;
          }
        }
      }
    }
  }
}

.incrementalWrap {
  @include display-flex;
  @include align-items(center);

  .button {
    border: 1px solid $theme-grey-400;
    background-color: $white;
    color: $theme-grey-700;
    font-weight: 800;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: $theme;
      border-color: $theme;
      color: $white;
    }
  }

  ._num {
    @include padding(0 10);
    font-weight: 700;
    font-size: 18px;
    min-width: 40px;
    text-align: center
  }
}

.uploadImageWrap {
  @include pos(r, null);

  &>input {
    @include opacity(0);
    @include pos(ab, null);
  }

  .inner {
    border: 1px solid $theme-grey-400;
    text-align: center;
    color: $theme-grey-700;
    cursor: pointer;
    @include radius(12px);
    @include padding(60 15);

    h5 {
      font-size: 18px;
      font-weight: 500;
    }

    p {
      font-size: 12px;
    }

    ._link {
      font-weight: 700;
      color: $theme-active;
      text-decoration: underline;
    }
  }

  &--disabled {
    cursor: no-drop;

    &>input {
      cursor: no-drop;
    }

    .inner {
      background-color: $theme-grey-200;
      cursor: no-drop;

      &>* {
        @include opacity(0.4);
        cursor: no-drop;
      }
    }
  }
}

.listActionDropdownBlock {
  @include pos(r, null);

  .toggleButton {
    @include display-flex(inline-flex);
    @include align-items(center);
    @include padding(0);
    @include transition(all 0.5s);
    @include circle(32px);
    text-align: center;
    line-height: 26px;
    font-size: 18px;
    border: none;
    color: $theme-grey-700;
    background-color: transparent;

    &:after {
      display: none;
    }

    &:hover {
      background-color: $theme-grey-300;
      color: $theme;
    }
  }

  .dropdown-menu {
    border: 1px solid $theme-grey-400;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    min-width: 200px;

    .dropdownUser {
      // @include display-grid;
      // @include grid-template-columns(40px 1fr);
      // @include align-items(center);
      // gap: 10px;
      text-align: center;
      @include padding(20);

      .imgBlock {
        @include circle(60px);
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }
    }

    .dropdown-item {
      @include padding(10 20);
      // @include radius(8px);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.7;
      // border-top: 1px solid $theme-grey-200;

      &.active {
        background-color: $white;
        color: $theme-secondary;
        font-weight: 700;
      }

      &>em {
        font-size: 18px;
      }

      &:hover {
        background-color: $theme-grey-200;
      }
    }
  }
}
