@import '../../../public/assets/scss/base';

#fullPageLoader {
    @include pos(f, 0);
    z-index: 2000;
    background-color: rgba($white, 0.8);
  
    .loaderInnder {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      @include square(100%);
      @include padding(50);
    }
  }