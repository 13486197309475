@import '../../../../../public/assets/scss/base';

.filter-bar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bank-details-text{
  font-weight:700 !important;
}
.payment-cadence-info{
  color: black !important;
}
$theme-gray: #999999 !default;
$theme-danger: #FF4848 !default;
$theme-spanish-gray: #8E979B !default;
$theme: #00D19F !default;

.courseDetail {
  @include padding-top(0);
  &__ {
    &card {
      background-color: $white;
      border: 1px solid $theme-grey-300;
      box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
      @include radius(6px);
      @include padding(20);

      p {
        line-height: 1.8;
      }

      .course_description {
        overflow-wrap: break-word;
      }

      .cursor-pointer {
        cursor: pointer;
      }

      .text-right {
        text-align: right !important;
      }

      .read-content-link {
        color: blue;
        cursor: pointer;
      }

      .content-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .strike {
        text-decoration: line-through;
      }

      ._metaInfo {
        min-width: 100px;

        label {
          color: $theme-grey-700;
          font-weight: 500;
        }
      }

      ._basics {
        p {
          color: $theme-grey-800;
          margin: 5px 0;
        }
      }
    }

    &cardTitle {
      font-size: 16px;
      font-weight: 700
    }

    &infoHead {
      @include pos(r, null);
      @include margin-left(-20);
      @include margin-right(-20);
      @include padding(0 20 20);
      @include margin-bottom(20);
      border-bottom: 1px solid $theme-grey-300;

      .imgBlock {
        display: block;
        overflow: hidden;
        @include square(92px);
        @include radius(5px);

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }

      h2 {
        font-size: 20px;
        font-weight: 700;
        color: $theme-secondary;
      }

      ._editCourseButton {
        background-color: $white;
        border: 1px solid $theme-grey-300;
        box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
        color: $theme-grey-900;

        &:hover {
          background-color: $theme;
          border-color: $theme;
          color: $white;
        }
      }
    }

    &imagesGrid {
      @include display-grid;
      @include grid-template-columns(repeat(auto-fill, minmax(100px, 1fr)));
      grid-gap: 10px;
      gap: 10px;

      .imgBlock {
        @include pos(r, null);
        @include radius(5px);
        height: 60px;
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }
    }

    &weatherGrid {
      @include display-grid;
      @include grid-template-columns(repeat(auto-fill, minmax(100px, 1fr)));
      grid-gap: 10px;
      gap: 10px;
      display: flex;

      

      .discountBadgeWeather {
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        background-color: #F8E751;
        color: $theme-grey-900;
        margin-top: 5px;
        margin-right: 8px;
        margin-bottom: 5px;
        @include padding(5 10);
        @include radius(30px);

        
      }

      .closeButton1 {
        
        @include circle(17px);
        line-height: 15px;
        border: 1px solid $theme-danger;
        color: white;
        background-color: $theme-danger;
        text-align: center;
      }

      .strike {
        text-decoration: line-through;
        background-color: $theme-danger;
      }

      .weatherWrapper{
        display: flex;
        margin-right: 25px;
      }

      .imgBlock {
        @include pos(r, null);
        @include radius(5px);
        height: 60px;
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }
    }

    &cardUserData {
      .userImage {
        @include circle(56px);
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
      }

      p {
        color: $theme-grey-700;
        font-weight: 500;
      }
    }

    &bookingSection {
      .daysLabel {
        background-color: $theme-secondary-S5;
        color: $theme-grey-900;
        font-size: 12px;
        font-weight: 600;
        min-width: 46px;
        text-align: center;
        display: inline-block;
        margin-right: 10px;
        @include padding(7 5);
        @include radius(30px);
      }
    }

    &integrationRow {
      @include display-grid;
      @include grid-template-columns(100px 1fr);
      @include padding(15 0);
      grid-gap: 15px;
      gap: 15px;

      &:not(:last-child) {
        border-bottom: 1px solid $theme-grey-300;
      }

      .logoBlock {
        width: 100px;
        height: 40px;
        line-height: 37px;
        border: 1px solid $theme-secondary-S3;
        text-align: center;
        padding: 0 5px;
        @include radius(8px);

        &.logoTee {
          background-color: $black;
          border-color: $black;

          &>img {
            @include filter(brightness(0) invert(1));
          }
        }
      }

      ._label {
        font-size: 12px;
        font-weight: 600;
        color: $theme-grey-600;
        @include margin-bottom(7);
      }


      h6 {
        font-size: 14px;
        font-weight: 700;
        color: $theme-secondary;
        margin: 0;
        word-break: break-word;
      }

      .statusBlock {
        font-size: 12px;
        margin-top: 15px;

        .connected {
          color: $theme-success;
        }

        .disconnected {
          color: $theme-danger;
        }
      }
    }

    &discountBlock {

      .ratingBlockHead {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        align-items: center;
      }

      .ratingBlock {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        background-color: #F8E751;
        color: $theme-grey-900;
        margin-right: 8px;
        @include padding(6 13);
       border-radius: 20px
      }

      .discountBadge {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        background-color: #F8E751;
        color: $theme-grey-900;
        margin-top: 5px;
        margin-right: 8px;
        margin-bottom: 5px;
        @include padding(7 15);
        @include radius(30px);
      }
    }
  }
  .text-align-center{
    text-align: center;
  }
  .add-products-btn{
    padding: 1px 11px !important;
  }
  .no-products-title {
    text-align: center;
    font-weight: bold;
}
.statusbutton{
  min-width: 120px !important;
}

}
.add-bank-btn{
  min-width: 135px !important;
}