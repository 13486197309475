@import '../../../public/assets/scss/base';

.privateLayoutWrapper {
  @include pos(r, null);
  @include experimental-value(min-height, calc(100vh));
  @include transition(all 0.4s);
  margin-left: 230px;
  background-color: #F6F6F6;

  &>main {
    @include padding(0 20);
    @include experimental-value(min-height, calc(100vh - 61px));
  }
}