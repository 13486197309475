@import "../../../public/assets/scss/base";

.usersWrapper1 {
  @include padding(20 0);
  max-width: 700px;

  .button-filter {
    background-color: $white;
    border-color: $theme-grey-300;
    color: $theme-secondary;
    font-weight: 600;
    font-family: $font-alternative;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.05),
      0px 1px 2px rgba(24, 24, 28, 0.05);
  }
}

.nav-tabs .nav-item .nav-link {
  color: black;
}

.todoTabs {
  @include pos(r, null);
  margin-bottom: 20px;
  border: none;
  & > .nav-item {
    border: none;
    .nav-link {
      font-size: 14px;
      font-weight: 600;
      color: #5f5f61;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      &.active {
        background-color: $white;
        color: #5f5f61;
        box-shadow: 0 5px 5px rgba($black, 0.1);
      }

      ._count {
        background-color: #e8e8e8;
        font-size: 10px;
        font-weight: 600;
        padding: 3px 5px;
        border-radius: 3px;
      }
    }
  }
}
