/*
* Base Layout Styles
*
* Very Miminal Layout Styles
*/
#{$ubtn-namespace}-block,
#{$ubtn-namespace}-stacked {
  display: block;
  width: 100%;
}
