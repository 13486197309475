@import '../../../../../public/assets/scss/base';

$theme-gray: #999999 !default;
$theme-danger: #FF4848 !default;
$theme-spanish-gray: #8E979B !default;
$theme: #00D19F !default;
.updateInfoModalWrapper3 {
    &.modal {
      .modal-dialog {
        max-width: 330px;
      }
  
      .modal-content {
        
        @include radius(24px);
      }
  
      .modal-body {
        @include padding(30);
        @include pos(r, null);
  
        @include media('<=550px'){
          @include padding(35 15);
        }
        li {
          border-bottom: 1px solid #e6e6e6;
          padding: 15px 0;
      }
        .closeButton {
          @include pos(ab, 20px 20px null null);
          @include circle(28px);
          line-height: 26px;
          border: 1px solid $theme-gray;
          color: $theme-gray;
          text-align: center;
  
          &:hover {
            border-color: $theme-danger;
            color: $theme-danger;
          }
        }
  
        p {
          color: $theme-spanish-gray;
        }
  
        .formWrap {
            display:flex;
            justify-content: center;
            align-items: center;
          h4 {
            font-size: 18px;
            font-weight: 600;
            
          }
          h6{
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
          }
          label {
            font-size: 15px;
            font-weight: 450;
          }
          .resendLink {
            background-color: transparent;
            border: none;
            outline: none;
            color: $theme-spanish-gray;
            @include padding(0);
  
            &:hover {
              color: $theme;
            }
          }

            .react-datepicker-wrapper{
                
            }
            .react-datepicker__input-container{
                
            }
            .react-datepicker__input-container input {
                display: block;
                width: 250px;
                padding:9px;
            }
        }
        
      }
    }
}