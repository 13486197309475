@import '../../../../../public/assets/scss/base';

.courseReviewWrapper {
  background-color: $white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  @include pos(r, null);
  @include radius(8px);

  &__ {
    &reviewItem {
      @include padding(15 20);

      &:not(:last-child) {
        border-bottom: 1px solid lighten($theme-grey-300, 5%);
      }

      .rImgBlock {
        @include circle(48px);
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }

      h5 {
        font-size: 16px;
        font-weight: 700;
        color: $theme-grey-800;
      }

      h6 {
        font-size: 13px;
        font-weight: 400;
        color: $theme-grey-600;
      }

      p {
        color: $theme-grey-700;
        font-weight: 500;
        line-height: 1.8;
      }

      ._rating {
        color: $theme-active;
        font-weight: 700;

        em {
          font-size: 120%;
        }
      }
    }
  }
}