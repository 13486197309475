.ibv {
  display: $ib;
  vertical-align: middle;
}

.color-white{
  color: $white;;
}

.color-theme {
  color: $theme;
}

.bg-theme {
  background-color: $theme;
}

.color-grey {
  color: $theme-grey;
}

.color-800 {
  color: $theme-grey-800;
}

.color-700 {
  color: $theme-grey-700;
}

.color-600 {
  color: $theme-grey-600;
}

// .color-500 {
//   color: $theme-grey-500;
// }

.color-400 {
  color: $theme-grey-400;
}

.color-300 {
  color: $theme-grey-300;
}

.color-200 {
  color: $theme-grey-200;
}

.color-s1 {
  color: $theme-secondary-S1;
}

.color-s2 {
  color: $theme-secondary-S2
}

.color-s3 {
  color: $theme-secondary-S3;
}

.color-s4 {
  color: $theme-secondary-S4;
}

.color-s5 {
  color: $theme-secondary-S5;
}

.bg-grey {
  background-color: $theme-grey;
}

.color-danger {
  color: $theme-danger
}

.color-active {
  color: $theme-active;
}

.color-success {
  color: $theme-green-dark;
}

.cursor-hand {
  cursor: pointer;
}

// Position
@for $j from 1 to 11 {
  .p-t#{$j} {
    @include pos(r, 0px + $j null null);
  }
}

// FONT SIZE
@for $j from 1 to 51 {
  .fontsize-#{$j} {
    font-size: 0px + $j !important;
  }
}

// FONT WWIGHT
$step: 100;

@for $j from 3 to 8 {
  $k: $j * $step;

  .fw#{$k} {
    font-weight: $k !important;
  }
}

// WIDTH
$step: 10;

@for $j from 1 to 31 {
  $k: $j * $step;

  .width-#{$k} {
    width: 0px + $k !important;
  }
}

//CheckBox Custome
.ch-checkbox {
  display: $ib;
  overflow: hidden;
  cursor: pointer;
  font-size: 14px;
  @include pos(r, null);
  @include transition(all 0.4s);

  & * {
    @include transition(all 0.4s);
  }

  &.ch-checkbox-bordered {

    input[type="checkbox"],
    input[type="radio"] {
      &:disabled {

        &+span,
        &~span {
          cursor: not-allowed;

          &:before {
            background-color: darken($white, 7%);
            border-color: darken($white, 10%);
          }
        }
      }

      &:checked {

        &+span,
        &~span {
          &:before {
            background-color: $theme;
            border-color: $theme;
          }
        }
      }

    }

    span {
      &:before {
        background-color: transparent;
        border: 1px solid $theme-light-grey;
      }
    }
  }


  input[type="checkbox"],
  input[type="radio"] {
    @include pos(ab, 0 null null 0);
    @include opacity(0);

    &:checked {

      &+span,
      &~span {
        &:before {
          background-color: lighten($theme, 8%);
        }

        &:after {
          @include opacity(1);
          @include transform(translateY(0) rotate(-45deg));
        }
      }
    }
  }

  span {
    display: $b;
    overflow: hidden;
    min-height: 20px;
    @include pos(r, null);
    @include padding-left(20);

    &.pl30 {
      @include padding-left(30);
    }

    &.pl25 {
      @include padding-left(25);
    }

    &:before {
      content: "";
      background-color: $theme-light-grey;
      @include radius(4px);
      @include pos(ab, 0 null null 0);
      @include square(20px);
      @include transition(inherit);
    }

    &:after {
      content: "";
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      height: 6px;
      width: 12px;
      @include opacity(0);
      @include pos(ab, 5px null null 4px);
      @include transform(translateY(-100%) rotate(-45deg));
    }
  }
}

//Custom Radio Button
.ch-radio {
  display: $ib;
  cursor: pointer;
  font-weight: 400;
  @include pos(r, null);
  @include fontsize(14);
  @include transition(all 0.4s);

  & * {
    @include transition(all 0.4s);
  }

  &.ch-radio-border {

    input[type="radio"],
    input[type="checkbox"] {
      &:checked {

        &+span,
        &~span {
          &:before {
            border-color: $theme;
            @include box-shadow(0 0 0 4px $theme inset);
          }
        }
      }
    }

    span {
      &:before {
        border: 1px solid $theme-grey;
        background-color: transparent;
      }

      &:after {
        @include square(8px);
        border-color: transparent;
        background-color: $white;
        top: 5px;
        left: 5px;
      }
    }
  }

  &.ch-radio-button {

    &>input[type="checkbox"],
    &>input[type="radio"] {
      &:checked {

        &+span,
        &~span {
          border-color: $theme;
          background-color: $theme;
          color: $white;
        }
      }
    }

    &>span {
      @include padding(10 15);
      @include radius(30px);
      min-width: 32px;
      border: 1px solid $theme-secondary-S3;
      font-size: 12px;
      font-weight: 600;
      color: $theme-secondary-S3;

      &:after,
      &:before {
        display: none;
      }
    }
  }

  &.ch-radio-border-theme {

    input[type="radio"],
    input[type="checkbox"] {
      &:checked {

        &+span,
        &~span {
          &:before {
            border-color: $theme;
          }
        }
      }
    }

    span {
      &:before {
        border: 1px solid darken($theme, 3%);
        background-color: transparent;
      }

      &:after {
        @include square(12px);
        border-color: transparent;
        top: 3px;
        left: 3px;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    @include pos(ab, 0 null null 0);
    @include opacity(0);

    &:checked {

      &+span,
      &~span {

        &:after {
          @include scale(1);
          @include opacity(1);
        }
      }
    }
  }

  span {
    display: $b;
    min-height: 20px;
    line-height: 18px;
    @include pos(r, null);
    @include padding-left(25);

    &:after,
    &:before {
      content: "";
      @include pos(ab, 0 null null 0);
      @include transition(inherit);
      @include square(18px);
      @include radius(50%);
    }

    &:before {
      background-color: darken($theme, 2%);
    }

    &:after {
      background-color: $theme;
      border: 3px solid $white;
      top: 0;
      left: 0;
      @include square(18px);
      @include scale(0);
      @include opacity(0);
    }
  }
}

.ch-radio-box {
  @include pos(r, null);
  cursor: pointer;
  display: block;

  &>input[type="radio"] {
    @include opacity(0);
    @include pos(ab, null);

    &:checked {

      &~span,
      &+span {
        background-color: lighten($theme, 10%);
        border-color: $theme;
        color: $theme;

        &:before {
          border-color: $theme;
          @include box-shadow(0 0 0 4px $theme inset);
        }
      }
    }
  }

  span {
    @include pos(r, null);
    @include padding(13 10 13 48);
    @include fontsize(14);
    @include radius(8px);
    font-weight: 600;
    display: block;
    border: 1px solid $theme-light-grey;

    @include media('>=568px') {
      min-width: 130px;
    }

    @include media('<=575px') {
      @include padding(13 10);
      text-align: center;

      &:before,
      &:after {
        display: none !important;
      }
    }

    &:before {
      content: '';
      @include circle(20px);
      @include pos(ab, 14px null null 15px);
      @include transition(all 0.3s);
      border: 1px solid $theme-light-grey;
    }

    &:after {
      content: '';
      @include circle(8px);
      @include pos(ab, 20px null null 21px);
      background-color: $white;
    }
  }
}

// Custome Switch button
.ch-switch {
  @include pos(r, null);
  cursor: pointer;

  & *,
  & *:after,
  & *:before {
    @include transition(all 0.4s);
  }

  input[type="checkbox"],
  input[type="radio"] {
    @include pos(ab, 0 null null 0);
    @include opacity(0);

    &:checked {

      &+span,
      &~span {
        background-color: $theme;
        border-color: $theme;

        &:after {
          left: 19px;
          background-color: $white;
        }
      }
    }
  }

  span {
    display: $ib;
    width: 38px;
    height: 20px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $theme-grey;
    @include pos(r, null);
    @include radius(30px);

    &:after {
      content: "";
      @include pos(ab, 1px null null 1px);
      @include radius(50%);
      @include square(16px);
      background-color: $theme;
      z-index: 1;
    }
  }
}

.ch-upload-button {
  @include pos(r, null);

  input[type="file"] {
    @include pos(ab, 0 null null 0);
    @include square(100%);
    @include opacity(0);
    cursor: pointer;
  }

  span {
    cursor: pointer;
  }
}

.gutters-3 {
  @include margin-left(-3);
  @include margin-right(-3);

  &>.col,
  &>[class*="col-"] {
    @include padding-left(3);
    @include padding-right(3);
  }
}

.gutters-5 {
  @include margin-left(-5);
  @include margin-right(-5);

  &>.col,
  &>[class*="col-"] {
    @include padding-left(5);
    @include padding-right(5);
  }
}

.gutters-7 {
  @include margin-left(-7);
  @include margin-right(-7);

  &>.col,
  &>[class*="col-"] {
    @include padding-left(7);
    @include padding-right(7);
  }
}

.gutters-10 {
  @include margin-left(-10);
  @include margin-right(-10);

  &>.col,
  &>[class*="col-"] {
    @include padding-left(10);
    @include padding-right(10);
  }
}

.gutters-xxl-30 {
  @include media('>1600px') {
    @include margin-left(-30);
    @include margin-right(-30);

    &>.col,
    &>[class*="col-"] {
      @include padding-left(30);
      @include padding-right(30);
    }
  }
}