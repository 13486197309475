@import '../../../../../public/assets/scss/base';

.filterModal {
  @include pos(r, null);
  font-family: $font-alternative;
  min-height: 100%;

  &__ {
    &header {
      @include padding(15 30);
      border-bottom: 1px solid $theme-grey-200;

      h4 {
        font-size: 18px;
        font-weight: 600;
      }

      .close {
        @include circle(32px);
        line-height: 32px;
        text-align: center;
        font-size: 18px;
        color: $theme-grey-800;
      }
    }

    &body {
      @include padding(15 30);

      .filterSection {
        @include padding(20 0);

        &:not(:last-child) {
          border-bottom: 1px solid $theme-grey-300
        }

        &> h6{
          font-size: 14px;
          font-weight: 600;
          color: $theme-secondary;
        }
      }

      .searchBlock {
        @include pos(r, null);
        min-width: 290px;

        .form-control {
          border-color: $theme-grey-300;
          height: 40px;
          @include radius(8px);
          @include padding-left(40);

          &:focus {
            border-color: $theme;
          }
        }

        em {
          @include pos(ab, 0 null null 0);
          line-height: 40px;
          width: 40px;
          text-align: center;
        }
      }

      .control-label {
        font-weight: 500;
        color: $theme-grey-700;
      }

      .inputRangeSlider {
        .input-range {
          height: 40px;
          @include margin(15 0);

          .input-range__label {
            color: $theme-grey-500;
            font-weight: 600;
            font-size: 12px;
            font-family: $font-alternative;

            &.input-range__label--min {
              .input-range__label-container {
                left: 0;
              }
            }

            &.input-range__label--max {
              .input-range__label-container {
                left: auto;
                right: 0;
              }
            }
          }

          .input-range__track {
            background-color: darkne($theme-grey-200, 1%);
            height: 6px;

            .input-range__track--active {
              background-color: $theme;
            }

            .input-range__slider-container {
              .input-range__label--value {
                // display: none;
                top: -40px;
                .input-range__label-container {
                  display: inline-block;
                  background-color: $white;
                  font-size: 12px;
                  font-weight: 500;
                  color: $theme-secondary;
                  border: 1px solid $theme-grey-200;
                  @include padding(2 4);
                  @include box-shadow(0 0 7px rgba($black, 0.1));
                }
              }

              .input-range__slider {
                @include pos(r, null);
                height: 24px;
                width: 24px;
                margin-top: -15px;
                margin-left: -12px;
                background-color: $white;
                border: none;
                box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.01), 0px 3px 8px rgba(0, 0, 0, 0.05), 0px 1px 6px rgba(0, 0, 0, 0.09), 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

                &:active{
                  @include scale(1);
                }

                // &:before {
                //   content: '';
                //   border: none;
                //   @include pos(ab, 0);
                //   @include box-shadow(0 0 0 3px $theme inset);
                //   @include radius(50%);
                // }
              }
            }
          }
        }
      }
    }

    &footer{
      @include padding(15 30);
      border-top: 1px solid $theme-grey-200;
    }
  }
}