//FONT SIZE
@mixin fontsize($pxval) {
  font-size: $pxval + px;


}

@mixin font-size($pxval: 16, $line: 1.5) {
  font-size: $pxval + px;
  line-height: $line;
}

// CLEAR AFTER MIXIN
// @include clear;
@mixin clear {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// ANCHOR HOVER & FOCUS
@mixin anchor($decoration: none, $outline: none) {

  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
  }
}

// OR SEPRATOR
@mixin or_seprator($wtd: 100%, $dis: block, $mrg: 30px auto, $brd-btm: 1px solid lighten(#000, 15%), $ht: 1px) {
  width: $wtd;
  display: $dis;
  margin: $mrg;
  border-bottom: $brd-btm;
  height: $ht;

  @include pos(rel, null);

  text-align: center;

  span {
    @include square(35px);

    @include fontsize(14);

    line-height: 35px;
    display: block;

    @include pos(ab, 0 null null 50%);

    @include translate(-50%, -50%);

    background-color: #fff;

    @include radius(50%);
  }
}

// Opacity Mixin
// @include opacity(0);
@mixin opacity($value) {
  $IEValue: $value * 100;

  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+ $IEValue + ")";
  filter: alpha(opacity=$IEValue);
}

// FONT WEIGHT MIXIN
// @include font-weight(thin);
@mixin font-weight($weight) {
  $weights: (thin: 100, extra-light: 200, ultra-light: 200, light: 300, normal: 400, book: 400, regular: 400, medium: 500, semi-bold: 600, demi-bold: 600, bold: 700, extra-bold: 800, ultra-bold: 900, heavy: 900, black: 900, ultra: 900, ultra-black: 900, extra-ultra: 900);

  $output: $weight;

  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

//truncate
@mixin truncate($truncation-boundary: '') {
  @if $truncation-boundary !='' {
    max-width: $truncation-boundary;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.peopleSayingSlider{
  .swiper-wrapper{
    align-items: center
  }
  }
