.tournament-title{
    display: grid !important;
    gap: 10px !important;
    min-width: 150px !important;
    grid-template-columns:120px 1fr !important;
}
.viewscoresbutton{
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    width:112px !important;
}