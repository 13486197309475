@import '../../../../public/assets//scss/base';

.coursesWrapper {
  @include padding(20 0);

  .button-filter {
    background-color: $white;
    border-color: $theme-grey-300;
    color: $theme-secondary;
    font-weight: 600;
    font-family: $font-alternative;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.05), 0px 1px 2px rgba(24, 24, 28, 0.05);
  }
  .text-align-right{
    text-align: right;
  }
  .notes-title{
    padding-top: 12px;
    padding-left: 20px;
  }
}