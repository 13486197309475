body,
html {
  height: 100%;
  width: 100%;
  font-family: $font, sans-serif;
  font-weight: 400;
  color: $theme-grey-900;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  // -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 0;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth !important;
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-x: hidden;
  // @include transition(all 500ms ease-in-out);
  background-color: $white;
}

* {
  -webkit-overflow-scrolling: touch;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  @include anchor;
  @include transition(all 0.5s);
  color: $theme;
  text-decoration: $n;
  outline: none !important;
  cursor: pointer;

  &:hover {
    color: darken($theme, 30%);
    text-decoration: $n;
  }

  &:focus {
    outline: none !important;
  }
}

.cursor-pointer{
  cursor: pointer;
}
input[type="file"] {
  cursor: pointer;

  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}

button,
input {

  &[type="button"],
  &[type="submit"] {
    cursor: pointer;
  }
}

textarea {
  resize: none
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
}
