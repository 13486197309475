.modalLayoutWrapper {
  &.modal {
    .modal-dialog {
      max-width: 500px;
    }

    .modal-content {
      @include radius(8px);
    }

    .modal-body {
      @include padding(50);
      @include pos(r, null);
      letter-spacing: 0.2px;

      @include media('<=550px') {
        @include padding(35 15);
      }

      .closeButton {
        @include pos(ab, 15px 15px null null);
        @include circle(28px);
        padding: 0 9px;
        line-height: 26px;
        border: 1px solid $theme-grey;
        color: $theme-grey;
        text-align: center;
        z-index: 1;

        &:hover {
          border-color: $theme-danger;
          color: $theme-danger;
        }
      }

      p {
        color: $theme-grey-700;

        a {
          color: $theme-active;
        }
      }

      .formWrap {
        h3 {
          font-size: 24px;
          font-weight: 700;
          color: $theme-grey-900;
          line-height: 1.4;
        }

        h4 {
          font-size: 20px;
          font-weight: 700;
          color: $theme-grey-700;
          line-height: 1.4;
        }

        .resendLink {
          background-color: transparent;
          border: none;
          outline: none;
          color: $theme-grey-600;
          @include padding(0);

          &:hover {
            color: $theme;
          }
        }
      }

    }
  }
}