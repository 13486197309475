.authWrapper {
  @include experimental-value(min-height, calc(100vh - 58px));
  @include display-flex;
  @include flex-direction(column);

  .authMain {
    @include padding(40 0);
  }

  // .authBackButton {
  //   padding: 10px 20px;

  //   &>a {
  //     @include circle(48px);
  //     text-align: center;
  //     line-height: 46px;
  //     border: 1px solid lighten($theme-light-grey, 5%);
  //     color: $theme-grey-800;

  //     &:hover {
  //       border-color: $theme;
  //       color: $theme;
  //     }
  //   }
  // }

  .authWrap {
    max-width: 400px;
    margin: 0 auto;

    h1 {
      font-weight: 800;
      font-size: 32px;
    }

    .formWrap {

      .control-label {
        font-size: 14px;
        font-weight: 500;
      }

      .form-control {
        height: 48px;
        border: 1px solid $theme-grey-300;
        @include radius(8px);

        &:focus {
          border-color: $theme;
          @include box-shadow(0 0 5px rgba($theme, 0.2));
        }
      }

      p {
        color: $theme-grey;
        font-weight: 500;

        a {
          color: $theme-grey-700;
          font-weight: 700;
        }
      }

      .form-group-verify {
        @include pos(r, null);
        @include radius(12px);
        @include padding(8 12);
        border: 1.5px solid $theme-grey-400;

        &.inValid {
          border-color: $theme-danger;
        }

        &>label {
          font-size: 12px;
          font-weight: 600;
          color: $theme-grey-600;
          letter-spacing: 0.3px;
        }

        .otpInvalid {
          @include pos(ab, 20px 10px null null);
          color: $theme-danger;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

.optInputWrap {
  &>div {
    &:not(:last-child) {
      @include margin-right(15);
    }
  }

  .optInput {
    width: 56px !important;
    height: 56px;
    border: 1px solid $theme-grey-600;
    // border-bottom: 1px solid $theme-light-silver;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    @include padding(5);
    @include radius(12px);

    &:not([value=""]) {
      border-color: $theme-grey-600;
    }

    &:focus,
    &:focus-visible {
      outline: none;
      border-color: $theme;
    }
  }
}
.page404Wrapper {
  @include pos(r, null);
  @include experimental-value(min-height, calc(100vh));
  @include display-flex;
  @include align-items(center);

  .page404Content {
    max-width: 345px;
    margin: 50px auto;

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    p {
      color: $theme-grey-700;
      font-size: 18px;
    }
  }
}