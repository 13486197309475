@include font-face($font, "#{$font-path}/PlusJakartaSans-ExtraLight", 200, normal, 'PlusJakartaSans-ExtraLight');
@include font-face($font, "#{$font-path}/PlusJakartaSans-Light", 300, normal, 'PlusJakartaSans-Light');
@include font-face($font, "#{$font-path}/PlusJakartaSans-Regular", 400, normal, 'PlusJakartaSans-Regular');
@include font-face($font, "#{$font-path}/PlusJakartaSans-Medium", 500, normal, 'PlusJakartaSans-Medium');
@include font-face($font, "#{$font-path}/PlusJakartaSans-SemiBold", 600, normal, 'PlusJakartaSans-SemiBold');
@include font-face($font, "#{$font-path}/PlusJakartaSans-Bold", 700, normal, 'PlusJakartaSans-Bold');
@include font-face($font, "#{$font-path}/PlusJakartaSans-ExtraBold", 800, normal, 'PlusJakartaSans-ExtraBold');

@font-face {
  font-family: 'icomoon';
  src: url('#{$icon-font-path}/icomoon.eot?36wql7');
  src: url('#{$icon-font-path}/icomoon.eot?36wql7#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}/icomoon.ttf?36wql7') format('truetype'),
  url('#{$icon-font-path}/icomoon.woff?36wql7') format('woff'),
  url('#{$icon-font-path}/icomoon.svg?36wql7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gear-filled:before {
  content: "\e921";
}

.icon-help-filled:before {
  content: "\e922";
}

.icon-user-filled:before {
  content: "\e91e";
}

.icon-calendar-filled:before {
  content: "\e91b";
}

.icon-golf-filled:before {
  content: "\e91c";
}

.icon-pie-chart:before {
  content: "\e91d";
}

.icon-users-filled:before {
  content: "\e91f";
}

.icon-wallet-filled:before {
  content: "\e920";
}

.icon-export:before {
  content: "\e91a";
}

.icon-vertical-elipse:before {
  content: "\e919";
}

.icon-bell:before {
  content: "\e918";
}

.icon-logout:before {
  content: "\e917";
}

.icon-calendar-mark:before {
  content: "\e906";
}

.icon-heart:before {
  content: "\e911";
}

.icon-settings:before {
  content: "\e912";
}

.icon-user-circle:before {
  content: "\e913";
}

.icon-printer:before {
  content: "\e916";
}

.icon-envelope:before {
  content: "\e914";
}

.icon-copy:before {
  content: "\e915";
}

.icon-document:before {
  content: "\e90e";
}

.icon-length:before {
  content: "\e90f";
}

.icon-slope:before {
  content: "\e910";
}

.icon-filter:before {
  content: "\e90d";
}

.icon-calendar:before {
  content: "\e900";
}

.icon-clock:before {
  content: "\e901";
}

.icon-credit-card:before {
  content: "\e902";
}

.icon-flag:before {
  content: "\e903";
}

.icon-flash:before {
  content: "\e904";
}

.icon-golf:before {
  content: "\e905";
}

.icon-location:before {
  content: "\e907";
}

.icon-map:before {
  content: "\e908";
}

.icon-pen:before {
  content: "\e909";
}

.icon-rotate-left:before {
  content: "\e90a";
}

.icon-search:before {
  content: "\e90b";
}

.icon-user:before {
  content: "\e90c";
}