// .scoredDialogWrapper{
//     @apply max-w-[980px] w-full m-4 min-w-min;
//     .scoresUpdateTableWrap {
//       .table {
//         thead {
//           tr {
//             th {
//               @apply py-4 px-1.5 text-white text-sm font-medium bg-secondary-950 font-primary text-start;
//               &:first-child {
//                 @apply pl-3;
//               }
//               &:last-child{
//                 @apply pr-3;
//               }
//             }
//           }
//         }
//         tbody {
//           tr {
//             &:nth-child(odd) {
//               td {
//                 @apply bg-white;
//               }
//             }
//             &:nth-child(even) {
//               td {
//                 @apply bg-silver-100;
//               }
//             }
//             td {
//               @apply py-4 px-1.5 text-grey-950 text-start text-sm font-medium;
//               &:first-child {
//                 @apply pl-3;
//               }
//               &:last-child{
//                 @apply pr-3;
//               }
  
//               .twFormControl{
//                 &--score{
//                   @apply w-16;
//                 }
//               }
//               // & + td{
//               //   @apply border-l border-grey-200;
//               // }
//             }
//           }
//         }
//       }
//     }
    
//   }

  // Variables
$primary-color: #007bff;
$secondary-color: #6c757d;
$border-color: #dee2e6;
$font-weight-bold: 700;
$padding-base: 1rem;
$input-padding: 0.5rem;
$input-border-radius: 0.25rem;
$button-padding: 0.75rem 1.25rem;


.score-modal-container{
padding : 0px !important;
} 

.score-modal-wrapper .modal-content{
    width: auto !important;   
}
.score-container {
    background-color: #f8f9fa;
    border-radius: 0.5rem;
    padding: $padding-base;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

    .score-header {
        border-bottom: 1px solid $border-color;
        padding-bottom: $padding-base;

        .score-title {
            font-size: 1.25rem;
            color: $secondary-color;
        }

        .btn-close {
            background: none;
            border: none;
            font-size: 1.5rem;
            color: $secondary-color;

            &:hover {
                color: $primary-color;
            }
        }
    }

    .score-details {
        margin-bottom: 2rem;

        .form-label {
            font-weight: $font-weight-bold;
            color: $secondary-color;
        }

        .score-tab{
            padding-top: 9px !important;
            padding-right: 7px !important;
        }

        .form-check-input {
            margin-top: 0.25rem;
            width: 1.25rem;
            height: 1.25rem;
        }

        .form-control {
            padding: $input-padding;
            border-radius: $input-border-radius;
            border: 1px solid $border-color;
        }

        .input-group {
            margin-bottom: $padding-base;
        }

        .checkbox-group {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

  .fw-semibold tr th{
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgb(21 50 48 / 1);
    color: rgb(255 255 255 /1);
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 1rem .375rem;
    text-align: start;
  }
  .fw-semibold tr .par-block{
    background-color: rgb(255 255 255 / 1) !important;
  }
.score-head-info{
    padding:1rem 1.5rem .375rem 1.5rem !important
  }
  .fw-semibold tr .score-block{
    background-color: rgb(239 239 239 / 1) !important;
  }
  .fw-semibold tr .par-status-block{
    background-color: rgb(255 255 255 / 1) !important;

  }
    .score-table {
        .table {
            margin: 0;
            th, td {
                text-align: center;
                vertical-align: middle;
                padding: 0.5rem;
            }

            .form-control-sm {
                padding: $input-padding;
                font-size: 0.875rem;
            }
        }
    }

    .score-footer {
        .btn {
            padding: $button-padding;
            font-size: 1rem;

            &.btn-outline-secondary {
                border-color: $secondary-color;
                color: $secondary-color;

                &:hover {
                    background-color: $secondary-color;
                    color: #fff;
                }
            }

            &.btn-primary {
                background-color: $primary-color;
                border-color: $primary-color;

                &:hover {
                    background-color: darken($primary-color, 10%);
                }
            }
        }

        .button-group {
            display: flex;
            gap: 1rem;
        }
    }
}
