@import '../../../../../public/assets/scss/base';

.accommodationsListWrapper{}

.tableLayout {
  background-color: $white;
  font-family: $font-alternative;
  @include radius(10px);

  .table {
    thead {
      filter: drop-shadow(0px, 2px, 0px, rgba(0, 0, 0, 0.05));

      tr {
        th {
          font-size: 12px;
          font-weight: 500;
          border-color: $theme-grey-200;
          @include padding(15 5);

          &:first-child {
            padding-left: 25px;
          }

          &:last-child {
            padding-right: 25px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          @include padding(15 5);
          border-color: $theme-grey-300;
          font-weight: 500;
          vertical-align: middle;

          &:first-child {
            padding-left: 25px;
          }

          &:last-child {
            padding-right: 25px;
          }

          .userContent {
            @include display-grid;
            @include grid-template-columns(40px 1fr);
            gap: 10px;

            .imgBlock {
              @include square(40px);
              @include radius(8px);
              overflow: hidden;

              &>img {
                @include square(100%);
                object-fit: cover;
              }
            }

            h5 {
              font-size: 14px;
              font-weight: 600;
              color: $theme-secondary;
            }

            h6 {
              font-size: 12px;
              font-weight: 400;
              color: $theme-grey-600;
            }
          }
        }
      }
    }
  }

  .paginationBlock {
    @include padding(20 25);

    h6 {
      font-size: 12px;
      color: $theme-grey-700;

      strong {
        font-weight: 700;
        color: $theme-grey-800;
      }
    }

    .button {
      background-color: $white;
      border: 1px solid $theme-platinum;
      color: $theme-secondary;
      font-weight: 600;
      font-family: $font-alternative;
      box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
      font-size: 14px;
      @include radius(4px);

      &:hover {
        background-color: $theme;
        color: $white;
      }

      &:disabled {
        @include opacity(0.7);
        cursor: not-allowed;
      }
    }
  }
}