@mixin font-face($font, $font-path, $font-weight, $font-style, $svgPath) {
  @font-face {
    font-family: $font;
    // src: url('#{$font-path}.eot');
    // src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
    src: url("#{$font-path}.woff2") format('woff2'),
    url("#{$font-path}.woff") format("woff");
    // url("#{$font-path}.ttf") format("truetype"),
    // url("#{$font-path}.svg##{$svgPath}") format("svg");
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }

  // Chrome for Windows rendering fix: http: //www.adtrak.co.uk/blog/font-face-chrome-rendering/

  // @media screen and (-webkit-min-device-pixel-ratio: 0) {
  //   @font-face {
  //     font-family: $font;
  //     src: url("#{$font-path}.svg##{$svgPath}") format("svg");
  //   }
  // }
}
