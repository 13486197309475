@import '../../../../../../public/assets/scss/base';



.timeList {
  list-style: disc !important;
  margin: auto !important;
  padding: 10px 20px;
  position: relative;

  .itinerary-container {
    // margin: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    
  }

  .itinerary-time-container {
    flex: 2;
    padding-left: 15px;
    padding-bottom: 20px;
  }

  

  li {
    // position: relative;
  }
  li::marker {
    color: transparent;
  }

  .timeline-date {
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .itinerary-bullet {
    display: block;

    min-width: 10px;
    min-height: 10px;
    
    border-radius: 50%;
    background-color: $theme;
    margin: 8px 0;
  }
  .timeline-track {
    display: flex;
    flex-direction: column;
    width: 15px; 
    flex: 0;
    justify-content: center;

  }

  .timeline-connecting-line {
    justify-content: center;
    justify-self: center;
    margin: 0 auto;

    display: flex;
    width: 2px;
    // max-height: 100%;
    flex: 2;
    background-color: #e9ecef;
  }

  .timeline-connecting-none {
      background-color: transparent;
  }
  
}

// .itinerary-bullet li:before {
//   height: 25px;
//   width: 25px;
//   background-color: #bbb;
//   border-radius: 50%;
//   display: inline-block;
// }

.bookingDetailWrapper {
  @include padding(20 0);
  font-family: $font-alternative;

  .buttonCancel {
    background-color: $white;
    color: $theme-danger;
    border: 1px solid $theme-grey-300;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
    line-height: 46px;
    font-family: $font-alternative;

    &:hover {
      background-color: $theme-danger;
      color: $white;
    }
  }

  hr {
    margin: 2rem 0;
  }

  .infoCard {
    background-color: $white;
    border: 1px solid $theme-grey-300;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
    @include radius(6px);
    @include padding(20);

    p {
      line-height: 1.8;
    }

    &__ {
      &cardTitle {
        font-size: 16px;
        font-weight: 700
      }

      &cardUserData {
        .userImage {
          @include circle(56px);
          overflow: hidden;

          &>img {
            @include square(100%);
            object-fit: cover;
          }
        }

        h3 {
          font-size: 18px;
          font-weight: 700;
        }

        p {
          color: $theme-grey-700;
          font-weight: 500;
        }
      }

      &booking {
        ._box {
          @include padding-bottom(25);
        }

        .status {
          @include padding(5 10);
          @include radius(30px);
          display: inline-block;
          font-size: 12px;
          font-weight: 500;

          &--active {
            background-color: $theme-tint-active;
            color: $theme-active;
          }

          &--danger {
            background-color: $theme-tint-danger;
            color: $theme-danger;
          }

          &--success {
            background-color: $theme-tint-success;
            color: $theme-green-dark;
          }

          .dot {
            @include circle(7);
            background-color: $theme-active;
          }
        }
      }

      &courseInfo {
        .imgBlock {
          display: block;
          overflow: hidden;
          @include square(92px);
          @include radius(5px);

          &>img {
            @include square(100%);
            object-fit: cover;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: 700;
          color: $theme-secondary;
        }
      }

      &review {
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.9;
        }
      }
    }



    /** test for booking itenary*/
    .card-body {
      // @include padding(30);
      font-size: 16px;

      h4 {
        font-size: 16px;
        font-weight: 700;

        @include media('<=991px') {
          font-size: 14px;
        }

        @include media('<=767px') {
          font-size: 13px;
        }
      }

      ._item {
        @include padding(9 0);

        label {
          font-weight: 500;
          color: $theme-grey-700;

          @include media('<=991px') {
            font-size: 13px;
          }

          small {
            font-size: 80%;
            color: $theme-grey-600;
          }
        }

        h6 {
          font-weight: 700;
          font-size: 16px;

          @include media('<=991px') {
            font-size: 14px;
          }

          @include media('<=767px') {
            font-size: 13px;
          }
        }

        h5 {
          font-size: 20px;
          font-weight: 700;
          color: $theme-grey-900;

          @include media('<=1199px') {
            font-size: 18px;
          }

          @include media('<=991px') {
            font-size: 16px;
          }

          @include media('<=767px') {
            font-size: 14px;
          }
        }
      }

      .itinerarySection {
        & > h2 {
          font-size: 16px;
          font-weight: 460;
          color: #8E979B;
        }
        .timelineWrapper {
          @include pos(r, null);
          // &:before {
          //   content: '';
          //   border-left: 1px solid $theme-grey-400;
          //   @include pos(ab, 10px null 10px 12px);
          // }
          .timelineBlockOuter {
            @include padding(20);
            @include radius(12px);
            @include pos(r, null);
            overflow: hidden;
            margin-bottom: 20px;
            // background-color: #F9F9F9;
          }
          .timelineBlock {
            @include pos(r, null);
            
            // @include padding-bottom(30);
            &:before {
              content: '';
              // border-left: 1px solid #F9F9F9;
              @include pos(ab, 40px null 20px 11px);
            }
  
            & > h4 {
              font-size: 16px;
              font-weight: 500;
              // background-color: $white;
              // @include padding(10 0);
              @include margin(0);
            }
  
            .timeBlock {
              @include padding(15 0 15 50);
              @include pos(r, null);
  
              .timeLineMetaIcons {
                @include display-flex;
                @include justify-content(center);
                @include flex-direction(column);
                @include align-items(center);
                @include pos(ab, 18px null null 0);
                width: 24px;
                gap: 10px;
                & > ._dot {
                  @include square(17px);
                  // background-color: #F9F9F9;
                  &:before {
                    content: '';
                    @include circle(10px);
                    // background-color: #00d19f;
                    background-color: $theme;
                  }
                }
                & > ._icon {
                  @include padding(5 0);
                  // background-color: #F9F9F9;
                  width: 24px;
                }
              }
  
              ._time {
                font-size: 16px;
                font-weight: 700;
                color: $theme-grey-200 !important;
                @include margin-bottom(15);
              }
  
              ._title {
                font-size: 16px;
                font-weight: 500;
              }
  
              p {
                font-size: 12px;
                color: grey;
              }
  
              ._label {
                font-size: 14px;
                color: grey;
                font-weight: 500;
  
                strong {
                  font-weight: 500;
                  color: black;
                }
              }
              ._price {
                font-size: 14px;
                color: grey;
                font-weight: 700;
  
                strong {
                  font-weight: 700;
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
}

.rating-no{
color:black;
}

.rating-wrapper{
  padding : 0 !important;
}

.timeBlockTime {
  font-weight: 400;
  letter-spacing: -0.5px;
  color: $theme-grey-600;
  font-size: 1.1em;
}
.tournament-detailed-container{
  border: 1px solid #E6E6E6 !important;
    box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04) !important;
    border-radius: 6px !important;
}