@import '../../../../../public/assets/scss/base/';
.btn-padding-custom{
    padding:0 15.8px !important;
}
.course-name-field{
    width:100px !important;
}


.courseslistWrapper {}

$table-border-radius: 10px;

.tableLayout {
  background-color: $white;
  font-family: $font-alternative;
  @include radius($table-border-radius);

  // for every first element in a row(the booking #), right align the text
  th, td {
    &:first-child {
      text-align: right;
    }
  }

  filter: drop-shadow(0 0 3px #0000001A);

  .table {
    thead {
      filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.05));

      tr {
        th {
          font-size: 12px;
          font-weight: 500;
          border-color: $theme-grey-200;
          @include padding(15 5);

          &:first-child {
            padding-left: 25px;
            border-top-left-radius: $table-border-radius;
          }

          &:last-child {
            padding-right: 25px;
            border-top-right-radius: $table-border-radius;
          }
        }
      }
    }
    
    td, th {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    
  }

  .paginationBlock {
    @include padding(20 25);

    h6 {
      font-size: 12px;
      color: $theme-grey-700;

      strong {
        font-weight: 700;
        color: $theme-grey-800;
      }
    }

    .button {
      background-color: $white;
      border: 1px solid $theme-platinum;
      color: $theme-secondary;
      font-weight: 600;
      font-family: $font-alternative;
      box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
      font-size: 14px;
      @include radius(4px);

      &:hover {
        background-color: $theme;
        color: $white;
      }

      &:disabled {
        @include opacity(0.7);
        cursor: not-allowed;
      }
    }
  }
}