@import '../../../../../public/assets/scss/base';

.accommodationDetail {
  @include padding(20 0);
  &__ {
    &card {
      background-color: $white;
      border: 1px solid $theme-grey-300;
      box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
      @include radius(6px);
      @include padding(20);

      p {
        line-height: 1.8;
      }

      ._metaInfo {
        min-width: 100px;

        label {
          color: $theme-grey-700;
          font-weight: 500;
        }
      }

      ._basics {
        p {
          color: $theme-grey-800;
          margin: 5px 0;
        }
      }
    }

    &cardTitle {
      font-size: 16px;
      font-weight: 700
    }

    &infoHead {
      @include pos(r, null);
      @include margin-left(-20);
      @include margin-right(-20);
      @include padding(0 20 20);
      @include margin-bottom(20);
      border-bottom: 1px solid $theme-grey-300;

      .imgBlock {
        display: block;
        overflow: hidden;
        @include square(92px);
        @include radius(5px);

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }

      h2 {
        font-size: 20px;
        font-weight: 700;
        color: $theme-secondary;
      }

      ._editCourseButton {
        background-color: $white;
        border: 1px solid $theme-grey-300;
        box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
        color: $theme-grey-900;

        &:hover {
          background-color: $theme;
          border-color: $theme;
          color: $white;
        }
      }
    }

    &imagesGrid {
      @include display-grid;
      @include grid-template-columns(repeat(auto-fill, minmax(100px, 1fr)));
      grid-gap: 10px;
      gap: 10px;

      .imgBlock {
        @include pos(r, null);
        @include radius(5px);
        height: 60px;
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }
    }

    &cardUserData {
      .userImage {
        @include circle(56px);
        overflow: hidden;

        &>img {
          @include square(100%);
          object-fit: cover;
        }
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
      }

      p {
        color: $theme-grey-700;
        font-weight: 500;
      }
    }
  }
  ._lablesBlock {
    @include display-flex;
    @include flex-wrap(wrap);
    gap: 5px;
    label {
      @include radius(30px);
      @include padding(7 10);
      color: $theme-grey-900;
      background-color: $theme-grey-200;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.read-content-link:hover {
  cursor: pointer;
}

.card-body {
  @include padding(0 0 10);
  @include radius(0 0 10px 10px);
  .table {
    // border: 1px solid $theme-grey-200;
    thead {
      th {
        background-color: $theme-grey-200;
        font-size: 12px;
        font-weight: 600;
        color: $theme-grey-700;
        border-width: 0;
        @include padding(15);
        &:last-child {
          width: 55px;
        }
      }
    }
    tbody {
      tr {
        &:first-child {
          td {
            border-top-width: 0;
          }
        }
      }
      td {
        @include padding(15);
        color: $theme-grey-900;
        border-bottom-width: 0;
        border-top-width: 1px;
        .deleteButton {
          background-color: transparent;
          
          border: none;
          padding: 0;
          outline: none;
          .deleteIcon {
            @include square(24px);
          }
        }

        .dropdown {
          button {
            background-color: transparent;
            border: none;
            color: $black;
            padding: 0;
            &:after {
              display: none;
            }
            &:hover,
            &:focus {
              background-color: transparent;
              color: $theme-active;
            }
          }
        }
      }
    }
  }
}