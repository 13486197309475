@import "../../../../public/assets/scss/base";

.dashboardWrapper {
  .dashboardWidget {
    @include padding(20 15);
    @include radius(10px);
    @include box-shadow(
      0px 0px 2px rgba(24, 24, 28, 0.02),
      0px 1px 2px rgba(24, 24, 28, 0.06)
    );
    background-color: $white;
    color: $theme-secondary;
    min-height: 100%;

    .listActionDropdownBlock {
      margin-top: -10px;
      margin-right: -5px;
    }

    h5 {
      font-size: 14px;
      font-weight: 600;
      color: $theme-secondary;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 30px;
      font-weight: 600;
      color: $theme-secondary;
      margin-bottom: 15px;
    }

    ._label {
      font-weight: 600;
      color: $theme-grey-600;
      span {
        font-weight: 500;
      }
      ._active {
        color: $theme-green;
      }
      ._inactive {
        color: $theme-danger;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 700;
    }

    p {
      color: $theme-grey-600;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .custom-width-col {
    width: 48.5% !important;
  }

  .custom-margin-right {
    margin-right: 10px;
  }

  .card {
    background-color: $white;
    border: 1px solid lighten($theme-grey-300, 7%);
    box-shadow: 0px 0px 5px rgba(24, 24, 28, 0.05),
      0px 1px 5px rgba(24, 24, 28, 0.05);
    @include radius(8px);

    .card-header {
      background-color: $white;
      border: none;
      @include padding(15);
      @include radius(8px 8px 0 0);

      h4 {
        font-size: 18px;
        font-weight: 600;
      }

      .form-select {
        font-weight: 600;
      }
    }
  }
}
