// CUSTOM CSS SCROLLBAR RESET DEFAULT
* {
  scrollbar-color: darken($white, 20%) $theme-grey-200;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: $theme-grey-200;
}

*::-webkit-scrollbar-track {
  @include box-shadow(inset 0 0 6px rgba(0, 0, 0, 0.3));
  border-radius: 10px;
  background-color: $theme-grey-200;
}

*::-webkit-scrollbar-thumb {
  @include box-shadow(inset 0 0 6px rgba(0, 0, 0, 0.3));
  border-radius: 10px;
  background-color: darken($white, 20%);
}

.container {
  max-width: 1250px;

  // @include media('<1600px') {
  //   max-width: 1170px;
  // }
}

main {
  .mainBodyWrapper {
    @include experimental-value(min-height, calc(100vh - 134px));
    background-color: $theme-grey-200;

    &.whiteBg {
      background-color: $white;
    }
  }
}

.markIcon {
  @include circle(105px);
  @include display-flex;
  @include justify-content(center);
  @include align-items(center);
  background: linear-gradient(180deg, #20CF82 0%, rgba(32, 207, 130, 0) 99.99%, rgba(32, 207, 130, 0.01) 100%);
  box-shadow: 0px 23.2px 50.6182px -27.4182px #81FFC8;
  font-size: 44px;
  color: $white;
  margin: 10px auto 40px;

  &.success {
    background: linear-gradient(180deg, #20CF82 0%, rgba(32, 207, 130, 0) 99.99%, rgba(32, 207, 130, 0.01) 100%);
  }

  &.error {
    background: linear-gradient(180deg, #EB4865 0%, rgba(237, 37, 61, 0.01) 99.99%, rgba(230, 48, 92, 0) 100%);
    box-shadow: 0px 42.1818px 50.6182px rgba(138, 80, 230, 0.12);
  }
}

.erroConfirmationModalWrap,
.successConfirmationModalWrap {

  &>h2,
  &>._title {
    font-size: 24px;
    font-weight: 800;
    color: $theme-grey-900;
    line-height: 1.4;
  }

  &>p {
    font-size: 16px;
    color: $theme-grey-600;
  }
}

.pageTitle {
  font-size: 20px;
  font-weight: 700;
  color: $theme-secondary;
  letter-spacing: 0.3px;
}

.commonBackButton {
  background-color: $white;
  color: $theme-secondary;
  border: 1px solid $theme-grey-300;
  box-shadow: 0px 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
  line-height: 38px;
  @include radius(8px);
}

.breadcrumbWrap {
  .breadcrumb {
    .breadcrumb-item {
      font-size: 14px;
      font-weight: 400;
      font-family: $font-alternative;

      &>a {
        color: $theme-grey-700;

        &:hover {
          color: $theme;
        }
      }

      &>span {
        color: $theme-secondary;
        font-weight: 700
      }
    }
  }
}

.asideFilterWrapper {
  @include pos(f, 0);
  @include square(100%);
  z-index: 1010;
  background-color: rgba($black, 0.7);

  .asideFilterInnerWrap {
    background-color: $white;
    max-width: 380px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @include pos(f, 0 0 0 null);
    // @include padding(20 30);
    // @include radius(15px 0 0 15px);
  }
}

nav {
  &.navTabs {
    @include display-flex;
    @include align-content(center);
    gap: 5px;
    font-family: $font-alternative;

    &>.nav-item {
      color: $theme-grey-700;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      display: inline-block;
      font-family: $font-alternative;
      border: none;
      box-shadow: none;
      outline: none;
      @include padding(10 15);
      @include radius(6px);

      &:hover,
      &.active {
        background-color: $white;
        color: $theme-secondary;
        box-shadow: 0px 1px 1px rgba(24, 24, 28, 0.04), 0px 3px 4px rgba(24, 24, 28, 0.04);
      }

      .count {
        font-size: 10px;
        background-color: $theme-platinum;
        @include padding(3 6);
        @include radius(3px);
      }
    }
  }
}