@import '../../../../../public/assets/scss/base';

.order-remove-button {
    color: $theme-danger;
    background-color: transparent;
    border: none;
    padding: 0;
    margin:  0;
    align-self: flex-end;
    font-size: 1em;
    flex: 0;
}

.order-title {
    margin: 0;
    padding: 0;
    flex: 1;
    align-self: flex-end;
    text-transform: capitalize;
    // bottom: 0;
}

.order-title-container {
    display: flex;
    justify-content: space-between;
}

.order-title {
    vertical-align: bottom;
    font-weight: 600;
}

.order-location {
    color: $theme-grey-600;
    text-transform: capitalize;
}