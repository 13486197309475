@import '../../../public/assets/scss/base';

.headerWrapper {
  @include padding(10 20);
  @include pos(r, null);
  border-bottom: 1px solid $theme-platinum;

  .searchBlock {
    @include pos(r, null);
    min-width: 290px;

    .form-control {
      border-color: $theme-grey-300;
      height: 40px;
      @include radius(8px);
      @include padding-left(40);

      &:focus {
        border-color: $theme;
      }
    }

    em {
      @include pos(ab, 0 null null 0);
      line-height: 40px;
      width: 40px;
      text-align: center;
    }
  }

  .userImage {
    @include circle(40px);
    display: block;
    overflow: hidden;

    &>img {
      @include square(100%);
      object-fit: cover;
    }
  }

  ._name {
    font-size: 14px;
    font-weight: 700;
    color: $theme-secondary;
  }

  ._email {
    font-size: 12px;
    font-weight: 400;
    color: $theme-secondary-S2;
  }
}