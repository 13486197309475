.border-none {
    border: none;
}

.accordion-header-text {
    font-weight: bold;
    color: black;
    font-size: larger;
}

.form-check-input:checked {
    background-color: #00D19F !important; /* Change this to your desired background color when checked */
    border-color: #00D19F;
}