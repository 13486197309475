@import '../../../../public/assets/scss/base';

.authSetupWrapper {
  .table-data {
    padding-top:10px;
    padding-bottom:10px; 
    text-align: center; 
    vertical-align: middle;
  }
  .status {
    @include padding(5 10);
    @include radius(30px);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;

    &--active {
      background-color: $theme-tint-active;
      color: $theme-active;
    }

    &--danger {
      background-color: $theme-tint-danger;
      color: $theme-danger;
    }

    &--success {
      background-color: $theme-tint-success;
      color: $theme-green-dark;
    }

    &--warning {
      background-color: $theme-tint-warning;
      color: $theme-warning-dark;
    }

    .dot {
      @include circle(7);
      background-color: $theme-active;
    }
  }
  .authSetupContainer {
    @include pos(r, null);
    max-width: 820px;
    margin: 0 auto;
    margin-top:20px;
    .profileWrapper__title {
      font-size: 18px;
    }
    .profileWrapper__clubtitle {
      margin-top:20px;
      font-size: 18px;
    }

    .stepWrap {
      @include pos(r, null);
      @include padding-left(45);
      .stepBlock {
        @include pos(r, null);
        @include padding(25 0);
        border-bottom: 1px solid $theme-grey-300;
        &:not(:last-child) {
          &:before {
            content: "";
            @include pos(ab, 25px null -25px -33px);
            border-left: 2px dashed $theme-grey-500;
          }
        }
        ._stepCheck {
          @include pos(ab, 25px null null -45px);
          @include circle(24px);
        }
        ._stepCheckGray {
          border: 2px solid $theme-grey-400;
          background-color: $white;
        }
        ._stepCheckGreen {
          border: 2px solid #00D19F;
          background-color: #00D19F;
        }
        & > h6 {
          font-size: 14px;
          font-weight: 500;
          color: $theme-grey-700;
          margin-bottom: 15px;
        }
        & > h4 {
          font-size: 16px;
          font-weight: 700;
          color: $theme-grey-900;
          margin-bottom: 10px;
        }
        & > p {
          font-size: 14px;
          font-weight: 500;
          color: $theme-grey-600;
          line-height: 1.7;
          margin-bottom: 10px;
        }
        .formSelect {
          width: 240px;
          .form-select {
            height: 40px;
            border-radius: 0;
            background-color: darken($white, 5%);
            border-color: transparent;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02rem;
          }
        }
        ._authButton {
          background-color: #b3f2a9;
          color: #0a501e;
        }

        ._uploadFile {
          @include pos(r, null);
          @include radius(12px);
          @include display-flex;
          @include align-items(center);
          @include padding(5);
          width: 355px;
          font-size: 14px;
          font-weight: 500;
          color: $theme-active;
          border: 1px solid $theme-grey-400;
          cursor: pointer;
          & > input {
            @include pos(ab, null null 0);
            @include opacity(0);
            visibility: hidden;
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: 1;
          }
          & > span {
            @include display-flex;
            @include align-items(center);
            gap: 5px;
            & > ._icon {
              @include square(24px);
            }
          }
        }

        ._uploadedFile {
          @include pos(r, null);
          @include padding(5);
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          font-weight: 600;
          border: 1px solid $theme-grey-400;
          overflow: hidden;
          border-radius: 12px;
          ._icon{
            @include square(20px);
            @include flex(0 0 20px);
            color: $theme-grey-500;
          }
          ._fileName{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          button{
            @include square(20px);
            @include flex(0 0 20px);
            color: $theme-danger;
            background-color: transparent;
            border: none;
            padding: 0;
            outline: none;
          }
        }
      }
      .faded {
        opacity: .60; /* Standard: FF gt 1.5, Opera, Safari, CSS3 */
        filter: alpha(opacity=60); /* IE lt 8 */
        -ms-filter: "alpha(opacity=60)"; /* IE 8 */
        -khtml-opacity: .60; /* Safari 1.x */
        -moz-opacity: .60; /* FF lt 1.5, Netscape */
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .form-select {
    height: 100%;
  }
}
