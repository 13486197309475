.userslist {}



.myModalClass {
    // border: 5px solid red;
}

.myModalHeadClass {
    // border: 5px solid red;
    // width: 1000px;
}

.myModalBodyClass {
    // border: 5px solid red;
    // width: 1000px;
    // height: 1000px;
}

.myModalDivClass {
    // border: 5px solid blue;
    // width: 1000px;
    // height: 1000px;

}


.Modelwidth{
    width: 800px;
}

.modal-dialog {
    // border: 5px solid green;
    // max-width: 1000px;
    // width: 1000px;
    // max-height: 1000px;
    // height: 1000px;

}

.jodit-container:not(.jodit_inline),
.jodit-container:not(.jodit_inline) .jodit-workplace {

    min-height: 200px !important;
}


.myBTnClass {
    min-width: 100px;
}


.imgPreview {
    width: 100px;
    height: 100px;
    border: 1px solid red;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    button {
        padding: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: transparent;
        font-weight: bolder;
        font-size: 200%;
        border: none;
        color: red;
        cursor: pointer;
    }
}

.tableImg {

    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid red;
}

.userIconImg {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border: 1px solid red;
}