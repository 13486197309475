.transactionlist {}

.accordion-button::after{
    margin-left: 20px !important;
}
.payout-block{
    margin-left: 10px;
}
.payout-main-btn{
   height:46px !important;
   padding: 0px 12px !important; 
}
