@import '../../../../../public/assets/scss/base';

$theme-gray: #999999 !default;
$theme-danger: #FF4848 !default;
$theme-spanish-gray: #8E979B !default;
$theme: #00D19F !default;

.updateInfoModalWrapper {
    &.modal {
      .modal-dialog {
        max-width: 550px;
      }
  
      .modal-content {
        @include radius(24px);
      }
  
      .modal-body {
        @include padding(40);
        @include pos(r, null);
  
        @include media('<=550px'){
          @include padding(35 15);
        }
        li {
          border-bottom: 1px solid #e6e6e6;
          padding: 15px 0;
      }
        .closeButton {
          @include pos(ab, 20px 20px null null);
          @include circle(28px);
          line-height: 26px;
          border: 1px solid $theme-gray;
          color: $theme-gray;
          text-align: center;
  
          &:hover {
            border-color: $theme-danger;
            color: $theme-danger;
          }
        }
  
        p {
          color: $theme-spanish-gray;
        }
  
        .formWrap {
          h4 {
            font-size: 18px;
            font-weight: 600;
          }
          .resendLink {
            background-color: transparent;
            border: none;
            outline: none;
            color: $theme-spanish-gray;
            @include padding(0);
  
            &:hover {
              color: $theme;
            }
          }
        }
        
      }
    }
}

.formStepper {
  &>h3 {
    font-size: 24px;
    font-weight: 800;
    color: $theme-grey-900;
  }

  &>h5, ._h5 {
    font-size: 18px;
    font-weight: 600;
    color: $theme-grey-700;
  }

  &>p {
    font-size: 16px;
    color: $theme-grey-700;
  }

  .isInvalidMessage {
    @include fontsize(13);
    font-weight: 500;
  
    &.text-danger {
      color: $theme-danger !important;
    }
  
    &>p {
      @include margin(0);
      @include fontsize(13);
      font-weight: 500;
    }
  }
  .courseFormDetail {
    li {
      border-bottom: 1px solid $theme-grey-300;
      @include padding(15 0);

      .control-label {
        font-size: 16px;
        font-weight: 700;
      }

      .form-control {
        width: 125px;
        text-align: center;
        font-weight: 500;
      }

      h5 {
        font-size: 16px;
        font-weight: 700;
      }

      .ch-radio {
        color: $theme-grey-800;
      }
    }
  }

  .uploadedImage {
    @include pos(r, null);

    &>span {
      @include square(72px);
      @include radius(8px);
      background-color: $theme-grey-400;
      overflow: hidden;
      display: block;

      &>img {
        @include square(100%);
        object-fit: cover;
      }
    }

    &>a {
      @include pos(ab, -10px -10px null null);
      @include circle(24px);
      border: 2px solid $white;
      background-color: $theme-grey-900;
      color: $white;
      font-size: 10px;
      text-align: center;
      line-height: 20px;

      &:hover {
        background-color: $theme-danger;
      }
    }
  }

  .mt-n15{
    margin-top: -15px;
  }

  .setTeeTimeBlock{
    @include margin-bottom(20);
    border-radius: 0;
    .accordion-collapse{
      border-bottom: 1px solid transparent;
      &.show{
        border-color: $theme-grey-300;
      }
    }
    .setTeeTimeBlock__Header{
      background-color: $theme-grey-200;
      padding: 12px 15px;
      border-radius: 8px;
      .setTeeTimeBlock__Header__title{
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .setDaysBlock {
    &>h6 {
      font-size: 16px;
      font-weight: 700;
    }

    ._dayTimesBlock {
      .form-select {
        height: 40px;
        width: 120px;
        font-size: 12px;
        font-weight: 500;
        @include radius(8px);
      }
    }


    .daysInputGroup {
      width: 90px;

      .form-control {
        height: 40px;
        font-weight: 600;
        border-right: none;
        padding-right: 0;
        @include radius(8px 0 0 8px);
      }

      .input-group-text {
        background-color: #ffffff;
        border-left: none;
        font-weight: 600;
        padding-left: 0;
        @include radius(0 8px 8px 0);
      }
    }

    .ch-checkbox {
      &>input[type="checkbox"] {
        &:checked {

          &+span,
          &~span {
            border-color: $theme;
            background-color: $theme;
            color: $white;
          }
        }
      }

      &>span {
        @include padding(5 11);
        @include radius(30px);
        border: 1px solid $theme-secondary-S3;
        font-size: 12px;
        font-weight: 600;
        color: $theme-secondary-S3;

        &:after,
        &:before {
          display: none;
        }
      }
    }

    ._allWeek {
      font-size: 12px;
      font-weight: 600;
      color: $theme-grey-700;
    }
  }
}

.custom-margin-left{
  margin-left: 20px;
}